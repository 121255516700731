import React from "react";
import { Link } from "react-router-dom";
import {
 AppBar,
 Box,
 CssBaseline,
 Divider,
 Drawer,
 IconButton,
 List,
 ListItem,
 ListItemButton,
 ListItemIcon,
 ListItemText,
 Toolbar,
 Typography,
 Modal,
 Accordion,
 AccordionSummary,
 AccordionDetails,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import OtherHousesIcon from "@mui/icons-material/OtherHouses";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import DirectionsCarFilledIcon from "@mui/icons-material/DirectionsCarFilled";
import BrandingWatermarkIcon from "@mui/icons-material/BrandingWatermark";
import NotificationsIcon from "@mui/icons-material/Notifications";
import HomeRepairServiceRoundedIcon from "@mui/icons-material/HomeRepairServiceRounded";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LoginRegister from "../pages/LoginRegister";
import AccountMenu from "./ProfileOption";
import Person4Icon from "@mui/icons-material/Person4";
import EventNoteIcon from "@mui/icons-material/EventNote";
const drawerWidth = 240;

const iconMapping = {
 OtherHousesIcon: OtherHousesIcon,
 PeopleOutlineIcon: PeopleOutlineIcon,
 DirectionsCarFilledIcon: DirectionsCarFilledIcon,
 BrandingWatermarkIcon: BrandingWatermarkIcon,
 HomeRepairServiceRoundedIcon: HomeRepairServiceRoundedIcon,
 Person4Icon: Person4Icon,
 EventNoteIcon: EventNoteIcon,
};

function ResponsiveDrawer({ isVendor, children }) {
 const [mobileOpen, setMobileOpen] = React.useState(false);
 const [selectedTab, setSelectedTab] = React.useState("/");
 const [modalOpen, setModalOpen] = React.useState(false);

 const handleDrawerToggle = () => {
  setMobileOpen(!mobileOpen);
 };

 const handleTabClick = (path) => {
  setSelectedTab(path);
 };

 const handleAvatarClick = () => {
  setModalOpen(true);
 };

 const handleModalClose = () => {
  setModalOpen(false);
 };

 const drawer = (
  <div>
   <Toolbar sx={{ bgcolor: "#1976d2" }}>
    <img src={"/logo.png"} alt="Logo" style={{ height: 60, margin: "auto" }} />
   </Toolbar>
   <Divider />
   <List>
    {isVendor
     ? [
        {
         text: "Vendor Profile",
         path: "/dashboard/vendor",
         icon: "OtherHousesIcon",
        },
        {
         text: "Service Requests",
         path: "/dashboard/service-requests",
         icon: "PeopleOutlineIcon",
        },
       ].map((item) => {
        const IconComponent = iconMapping[item.icon];
        const isSelected = selectedTab === item.path;
        return (
         <ListItem key={item.text} disablePadding>
          <ListItemButton
           component={Link}
           to={item.path}
           onClick={() => handleTabClick(item.path)}
           sx={{
            bgcolor: isSelected ? "#1976d2" : "inherit",
            color: isSelected ? "white" : "inherit",
            "&:hover": {
             bgcolor: "#1976d2",
             color: "white",
            },
           }}
          >
           <ListItemIcon sx={{ color: isSelected ? "white" : "inherit" }}>
            {IconComponent && <IconComponent />}
           </ListItemIcon>
           <ListItemText primary={item.text} />
          </ListItemButton>
         </ListItem>
        );
       })
     : [
        {
         text: "Home",
         path: "/dashboard",
         icon: "OtherHousesIcon",
        },
        {
         text: "User",
         path: "/dashboard/users",
         icon: "PeopleOutlineIcon",
        },
        {
         text: "Cars",
         path: "/dashboard/cars",
         icon: "DirectionsCarFilledIcon",
        },
        {
         text: "Brands",
         path: "/dashboard/brands",
         icon: "BrandingWatermarkIcon",
        },
        {
         text: "Vendor",
         path: "/dashboard/vendor",
         icon: "HomeRepairServiceRoundedIcon",
        },
        {
         text: "Profile",
         path: "/dashboard/profile",
         icon: "Person4Icon",
        },
        {
         text: "Slots", // New tab for Slots
         path: "/dashboard/slots",
         icon: "EventNoteIcon",
        },
        {
         text: "Payment setting", // New tab for Slots
         path: "/dashboard/payment-setting",
         icon: "EventNoteIcon",
        },
       ].map((item) => {
        const IconComponent = iconMapping[item.icon];
        const isSelected = selectedTab === item.path;
        return (
         <ListItem key={item.text} disablePadding>
          <ListItemButton
           component={Link}
           to={item.path}
           onClick={() => handleTabClick(item.path)}
           sx={{
            bgcolor: isSelected ? "#1976d2" : "inherit",
            color: isSelected ? "white" : "inherit",
            "&:hover": {
             bgcolor: "#1976d2",
             color: "white",
            },
           }}
          >
           <ListItemIcon sx={{ color: isSelected ? "white" : "inherit" }}>
            {IconComponent && <IconComponent />}
           </ListItemIcon>
           <ListItemText primary={item.text} />
          </ListItemButton>
         </ListItem>
        );
       })}
    <Accordion>
     <AccordionSummary expandIcon={<ExpandMoreIcon />}>
      <Typography>Products</Typography>
     </AccordionSummary>
     <AccordionDetails>
      <List>
       <ListItem disablePadding>
        <ListItemButton
         component={Link}
         to="/dashboard/product/categories"
         onClick={() => handleTabClick("/dashboard/product/categories")}
         sx={{
          bgcolor:
           selectedTab === "/dashboard/product/categories"
            ? "#1976d2"
            : "inherit",
          color:
           selectedTab === "/dashboard/product/categories"
            ? "white"
            : "inherit",
          "&:hover": {
           bgcolor: "#1976d2",
           color: "white",
          },
         }}
        >
         <ListItemText primary="Categories" />
        </ListItemButton>
       </ListItem>
       <ListItem disablePadding>
        <ListItemButton
         component={Link}
         to="/dashboard/product/subcategories"
         onClick={() => handleTabClick("/dashboard/product/subcategories")}
         sx={{
          bgcolor:
           selectedTab === "/dashboard/product/subcategories"
            ? "#1976d2"
            : "inherit",
          color:
           selectedTab === "/dashboard/product/subcategories"
            ? "white"
            : "inherit",
          "&:hover": {
           bgcolor: "#1976d2",
           color: "white",
          },
         }}
        >
         <ListItemText primary="Sub Categories" />
        </ListItemButton>
       </ListItem>
       <ListItem disablePadding>
        <ListItemButton
         component={Link}
         to="/dashboard/product"
         onClick={() => handleTabClick("/dashboard/product")}
         sx={{
          bgcolor: selectedTab === "/dashboard/product" ? "#1976d2" : "inherit",
          color: selectedTab === "/dashboard/product" ? "white" : "inherit",
          "&:hover": {
           bgcolor: "#1976d2",
           color: "white",
          },
         }}
        >
         <ListItemText primary="Products" />
        </ListItemButton>
       </ListItem>
       <ListItem disablePadding>
        <ListItemButton
         component={Link}
         to="/dashboard/product/prices"
         onClick={() => handleTabClick("/dashboard/product/prices")}
         sx={{
          bgcolor:
           selectedTab === "/dashboard/product/prices" ? "#1976d2" : "inherit",
          color:
           selectedTab === "/dashboard/product/prices" ? "white" : "inherit",
          "&:hover": {
           bgcolor: "#1976d2",
           color: "white",
          },
         }}
        >
         <ListItemText primary="Prices by Car" />
        </ListItemButton>
       </ListItem>
       <ListItem disablePadding>
        <ListItemButton
         component={Link}
         to="/dashboard/product/spare-parts"
         onClick={() => handleTabClick("/dashboard/product/spare-parts")}
         sx={{
          bgcolor:
           selectedTab === "/dashboard/product/spare-parts"
            ? "#1976d2"
            : "inherit",
          color:
           selectedTab === "/dashboard/product/spare-parts"
            ? "white"
            : "inherit",
          "&:hover": {
           bgcolor: "#1976d2",
           color: "white",
          },
         }}
        >
         <ListItemText primary="Spare Products" />
        </ListItemButton>
       </ListItem>
       <ListItem disablePadding>
        <ListItemButton
         component={Link}
         to="/dashboard/product/spare-parts/price"
         onClick={() => handleTabClick("/dashboard/product/spare-parts/price")}
         sx={{
          bgcolor:
           selectedTab === "/dashboard/product/spare-parts/price"
            ? "#1976d2"
            : "inherit",
          color:
           selectedTab === "/dashboard/product/spare-parts/price"
            ? "white"
            : "inherit",
          "&:hover": {
           bgcolor: "#1976d2",
           color: "white",
          },
         }}
        >
         <ListItemText primary="Spare Products price acording to car" />
        </ListItemButton>
       </ListItem>
       {/* Add more nested items as needed */}
      </List>
     </AccordionDetails>
    </Accordion>
    <Accordion>
     <AccordionSummary expandIcon={<ExpandMoreIcon />}>
      <Typography>Order Management</Typography>
     </AccordionSummary>
     <AccordionDetails>
      <List>
       <ListItem disablePadding>
        <ListItemButton
         component={Link}
         to="/dashboard/order-management"
         onClick={() => handleTabClick("/dashboard/order-management")}
         sx={{
          bgcolor:
           selectedTab === "/dashboard/order-management"
            ? "#1976d2"
            : "inherit",
          color:
           selectedTab === "/dashboard/order-management" ? "white" : "inherit",
          "&:hover": {
           bgcolor: "#1976d2",
           color: "white",
          },
         }}
        >
         <ListItemText primary="Manage Order" />
        </ListItemButton>
       </ListItem>

       {/* Add more nested items as needed */}
      </List>
     </AccordionDetails>
    </Accordion>
    <Accordion>
     <AccordionSummary expandIcon={<ExpandMoreIcon />}>
      <Typography>Review</Typography>
     </AccordionSummary>
     <AccordionDetails>
      <List>
       <ListItem disablePadding>
        <ListItemButton
         component={Link}
         to="/dashboard/review"
         onClick={() => handleTabClick("/dashboard/review")}
         sx={{
          bgcolor: selectedTab === "/dashboard/review" ? "#1976d2" : "inherit",
          color: selectedTab === "/dashboard/review" ? "white" : "inherit",
          "&:hover": {
           bgcolor: "#1976d2",
           color: "white",
          },
         }}
        >
         <ListItemText primary="Customer Reviews" />
        </ListItemButton>
       </ListItem>
       <ListItem disablePadding>
        <ListItemButton
         component={Link}
         to="/dashboard/partner-review"
         onClick={() => handleTabClick("/dashboard/partner-review")}
         sx={{
          bgcolor:
           selectedTab === "/dashboard/partner-review" ? "#1976d2" : "inherit",
          color:
           selectedTab === "/dashboard/partner-review" ? "white" : "inherit",
          "&:hover": {
           bgcolor: "#1976d2",
           color: "white",
          },
         }}
        >
         <ListItemText primary="Partner Reviews" />
        </ListItemButton>
       </ListItem>
      </List>
     </AccordionDetails>
    </Accordion>
    <Accordion>
     <AccordionSummary expandIcon={<ExpandMoreIcon />}>
      <Typography>Testimonials</Typography>
     </AccordionSummary>
     <AccordionDetails>
      <List>
       <ListItem disablePadding>
        <ListItemButton
         component={Link}
         to="/dashboard/testimonials-vid"
         onClick={() => handleTabClick("/dashboard/testimonials-vid")}
         sx={{
          bgcolor:
           selectedTab === "/dashboard/testimonials-vid"
            ? "#1976d2"
            : "inherit",
          color:
           selectedTab === "/dashboard/testimonials-vid" ? "white" : "inherit",
          "&:hover": {
           bgcolor: "#1976d2",
           color: "white",
          },
         }}
        >
         <ListItemText primary="Testimonials Videos" />
        </ListItemButton>
       </ListItem>
       <ListItem disablePadding>
        <ListItemButton
         component={Link}
         to="/dashboard/offers"
         onClick={() => handleTabClick("/dashboard/offers")}
         sx={{
          bgcolor: selectedTab === "/dashboard/offers" ? "#1976d2" : "inherit",
          color: selectedTab === "/dashboard/offers" ? "white" : "inherit",
          "&:hover": {
           bgcolor: "#1976d2",
           color: "white",
          },
         }}
        >
         <ListItemText primary="Testimonials Banner" />
        </ListItemButton>
       </ListItem>

       {/* Add more nested items as needed */}
      </List>
     </AccordionDetails>
    </Accordion>
    <Accordion>
     <AccordionSummary expandIcon={<ExpandMoreIcon />}>
      <Typography>City Services</Typography>
     </AccordionSummary>
     <AccordionDetails>
      <List>
       <ListItem disablePadding>
        <ListItemButton
         component={Link}
         to="/dashboard/city-services"
         onClick={() => handleTabClick("/dashboard/city-services")}
         sx={{
          bgcolor:
           selectedTab === "/dashboard/city-services" ? "#1976d2" : "inherit",
          color:
           selectedTab === "/dashboard/city-services" ? "white" : "inherit",
          "&:hover": {
           bgcolor: "#1976d2",
           color: "white",
          },
         }}
        >
         <ListItemText primary="Services-City" />
        </ListItemButton>
       </ListItem>
       <ListItem disablePadding>
        <ListItemButton
         component={Link}
         to="/dashboard/faq"
         onClick={() => handleTabClick("/dashboard/faq")}
         sx={{
          bgcolor: selectedTab === "/dashboard/faq" ? "#1976d2" : "inherit",
          color: selectedTab === "/dashboard/faq" ? "white" : "inherit",
          "&:hover": {
           bgcolor: "#1976d2",
           color: "white",
          },
         }}
        >
         <ListItemText primary="FAQ" />
        </ListItemButton>
       </ListItem>
       {/* <ListItem disablePadding>
        <ListItemButton
         component={Link}
         to="/dashboard/reviews"
         onClick={() => handleTabClick("/dashboard/reviews")}
         sx={{
          bgcolor: selectedTab === "/dashboard/reviews" ? "#1976d2" : "inherit",
          color: selectedTab === "/dashboard/reviews" ? "white" : "inherit",
          "&:hover": {
           bgcolor: "#1976d2",
           color: "white",
          },
         }}
        >
         <ListItemText primary="Offers" />
        </ListItemButton>
       </ListItem> */}
       {/* Add more nested items as needed */}
      </List>
     </AccordionDetails>
    </Accordion>
    <Accordion>
     <AccordionSummary expandIcon={<ExpandMoreIcon />}>
      <Typography>Company Profile</Typography>
     </AccordionSummary>
     <AccordionDetails>
      <List>
       <ListItem disablePadding>
        <ListItemButton
         component={Link}
         to="/dashboard/about-us"
         onClick={() => handleTabClick("/dashboard/about-us")}
         sx={{
          bgcolor:
           selectedTab === "/dashboard/about-us" ? "#1976d2" : "inherit",
          color: selectedTab === "/dashboard/about-us" ? "white" : "inherit",
          "&:hover": {
           bgcolor: "#1976d2",
           color: "white",
          },
         }}
        >
         <ListItemText primary="About" />
        </ListItemButton>
       </ListItem>
       <ListItem disablePadding>
        <ListItemButton
         component={Link}
         to="/dashboard/privacy-policy"
         onClick={() => handleTabClick("/dashboard/privacy-policy")}
         sx={{
          bgcolor:
           selectedTab === "/dashboard/privacy-policy" ? "#1976d2" : "inherit",
          color:
           selectedTab === "/dashboard/privacy-policy" ? "white" : "inherit",
          "&:hover": {
           bgcolor: "#1976d2",
           color: "white",
          },
         }}
        >
         <ListItemText primary="Privacy-Policy" />
        </ListItemButton>
       </ListItem>
       <ListItem disablePadding>
        <ListItemButton
         component={Link}
         to="/dashboard/terms-conditions"
         onClick={() => handleTabClick("/dashboard/terms-conditions")}
         sx={{
          bgcolor:
           selectedTab === "/dashboard/terms-conditions"
            ? "#1976d2"
            : "inherit",
          color:
           selectedTab === "/dashboard/terms-conditions" ? "white" : "inherit",
          "&:hover": {
           bgcolor: "#1976d2",
           color: "white",
          },
         }}
        >
         <ListItemText primary="Terms-Conditions" />
        </ListItemButton>
       </ListItem>
       <ListItem disablePadding>
        <ListItemButton
         component={Link}
         to="/dashboard/refund-cancelation"
         onClick={() => handleTabClick("/dashboard/terms-conditions")}
         sx={{
          bgcolor:
           selectedTab === "/dashboard/refund-cancelation"
            ? "#1976d2"
            : "inherit",
          color:
           selectedTab === "/dashboard/refund-cancelation"
            ? "white"
            : "inherit",
          "&:hover": {
           bgcolor: "#1976d2",
           color: "white",
          },
         }}
        >
         <ListItemText primary="Refund And Cancelation" />
        </ListItemButton>
       </ListItem>
       <ListItem disablePadding>
        <ListItemButton
         component={Link}
         to="/dashboard/contact-us"
         onClick={() => handleTabClick("/dashboard/contact-us")}
         sx={{
          bgcolor:
           selectedTab === "/dashboard/contact-us" ? "#1976d2" : "inherit",
          color: selectedTab === "/dashboard/contact-us" ? "white" : "inherit",
          "&:hover": {
           bgcolor: "#1976d2",
           color: "white",
          },
         }}
        >
         <ListItemText primary="Contact US" />
        </ListItemButton>
       </ListItem>
       {/* Add more nested items as needed */}
      </List>
     </AccordionDetails>
    </Accordion>
    <Accordion>
     <AccordionSummary expandIcon={<ExpandMoreIcon />}>
      <Typography>Account</Typography>
     </AccordionSummary>
     <AccordionDetails>
      <List>
       <ListItem disablePadding>
        <ListItemButton
         component={Link}
         to="/dashboard/wallets"
         onClick={() => handleTabClick("/dashboard/wallets")}
         sx={{
          bgcolor: selectedTab === "/dashboard/wallets" ? "#1976d2" : "inherit",
          color: selectedTab === "/dashboard/wallets" ? "white" : "inherit",
          "&:hover": {
           bgcolor: "#1976d2",
           color: "white",
          },
         }}
        >
         <ListItemText primary="Wallets" />
        </ListItemButton>
       </ListItem>
       <ListItem disablePadding>
        <ListItemButton
         component={Link}
         to="/dashboard/coupon-codes"
         onClick={() => handleTabClick("/dashboard/coupon-codes")}
         sx={{
          bgcolor:
           selectedTab === "/dashboard/coupon-codes" ? "#1976d2" : "inherit",
          color:
           selectedTab === "/dashboard/coupon-codes" ? "white" : "inherit",
          "&:hover": {
           bgcolor: "#1976d2",
           color: "white",
          },
         }}
        >
         <ListItemText primary="coupon-codes" />
        </ListItemButton>
       </ListItem>
       <ListItem disablePadding>
        <ListItemButton
         component={Link}
         to="/dashboard/todo"
         onClick={() => handleTabClick("/dashboard/todo")}
         sx={{
          bgcolor: selectedTab === "/dashboard/todo" ? "#1976d2" : "inherit",
          color: selectedTab === "/dashboard/todo" ? "white" : "inherit",
          "&:hover": {
           bgcolor: "#1976d2",
           color: "white",
          },
         }}
        >
         <ListItemText primary="Task" />
        </ListItemButton>
       </ListItem>
       <ListItem disablePadding>
        <ListItemButton
         component={Link}
         to="/dashboard/refer-and-earn"
         onClick={() => handleTabClick("/dashboard/refer-and-earn")}
         sx={{
          bgcolor:
           selectedTab === "/dashboard/refer-and-earn" ? "#1976d2" : "inherit",
          color:
           selectedTab === "/dashboard/refer-and-earn" ? "white" : "inherit",
          "&:hover": {
           bgcolor: "#1976d2",
           color: "white",
          },
         }}
        >
         <ListItemText primary="Refer And Earn" />
        </ListItemButton>
       </ListItem>
       {/* Add more nested items as needed */}
      </List>
     </AccordionDetails>
    </Accordion>
   </List>
  </div>
 );

 return (
  <Box sx={{ display: "flex" }}>
   <CssBaseline />
   <AppBar
    position="fixed"
    sx={{
     width: { sm: `calc(100% - ${drawerWidth}px)` },
     ml: { sm: `${drawerWidth}px` },
    }}
   >
    <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
     <IconButton
      color="inherit"
      aria-label="open drawer"
      edge="start"
      onClick={handleDrawerToggle}
      sx={{ mr: 2, display: { sm: "none" } }}
     >
      <MenuIcon />
     </IconButton>
     <Typography variant="h6" noWrap component="div">
      Car Expert
     </Typography>
     <Box
      sx={{
       display: "flex",
       alignItems: "center",
       justifyContent: "center",
       gap: "10px",
      }}
     >
      <AccountMenu />
      <NotificationsIcon />
     </Box>
    </Toolbar>
   </AppBar>
   <Box
    component="nav"
    sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
    aria-label="mailbox folders"
   >
    <Drawer
     variant="temporary"
     open={mobileOpen}
     onClose={handleDrawerToggle}
     ModalProps={{
      keepMounted: true,
     }}
     sx={{
      display: { xs: "block", sm: "none" },
      "& .MuiDrawer-paper": {
       boxSizing: "border-box",
       width: drawerWidth,
      },
     }}
    >
     {drawer}
    </Drawer>
    <Drawer
     variant="permanent"
     sx={{
      display: { xs: "none", sm: "block" },
      "& .MuiDrawer-paper": {
       boxSizing: "border-box",
       width: drawerWidth,
      },
     }}
     open
    >
     {drawer}
    </Drawer>
   </Box>
   <Box
    component="main"
    sx={{
     flexGrow: 1,
     p: 3,
     width: { sm: `calc(100% - ${drawerWidth}px)` },
    }}
   >
    <Toolbar />
    {children}
   </Box>
   <Modal
    open={modalOpen}
    onClose={handleModalClose}
    aria-labelledby="login-register-modal"
    aria-describedby="login-register-modal-description"
   >
    <Box
     sx={{
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: 400,
      bgcolor: "background.paper",
      border: "2px solid #000",
      boxShadow: 24,
      p: 4,
     }}
    >
     <LoginRegister />
    </Box>
   </Modal>
  </Box>
 );
}

export default ResponsiveDrawer;
